<template>
  <div id="fixed-actionDialog">
    <el-dialog
      title="区域操作"
      :visible.sync="show"
      :close-on-press-escape="false"
      @open="initCoord"
      :before-close="reset"
    >
      <gaode-map
        @mapClick="mapClick"
        ref="gaodeMap"
        :initCoord="defaultCoord"
      />
      <el-form ref="form" :model="form" label-width="50px">
        <el-form-item label="省">
          <el-input v-model="form.province" disabled></el-input>
        </el-form-item>
        <el-form-item label="市">
          <el-input v-model="form.city" disabled></el-input>
        </el-form-item>
        <el-form-item label="区">
          <el-input v-model="form.district" disabled></el-input>
        </el-form-item>
        <el-form-item label="街道">
          <el-input v-model="form.street"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {
    gaodeMap: () => import("@/components/gaode-map.vue"),
  },
  data() {
    return {
      show: false,
      loading: false,
      areaId: "",
      form: {
        province: "",
        city: "",
        district: "",
        street: "",
        lng: "",
        lat: "",
      },
      defaultCoord: {
        lng: "",
        lat: "",
      },
    };
  },
  methods: {
    initCoord() {
      if (this.form.lng && this.form.lat) {
        Object.assign(this.defaultCoord, {
          lng: this.form.lng,
          lat: this.form.lat,
        });
      }
    },
    reset(done) {
      this.$refs.gaodeMap.reset();
      this.$set(this, "defaultCoord", {
        lng: "",
        lat: "",
      });
      this.$set(this, "form", {
        province: "",
        city: "",
        district: "",
        street: "",
        lng: "",
        lat: "",
      });
      done && done();
    },
    mapClick(info) {
      this.$set(this, "form", {
        province: info.addressComponent.province,
        city: info.addressComponent.city,
        district: info.addressComponent.district,
        street:
          info.addressComponent.street + info.addressComponent.streetNumber,
        lng: info.longitude,
        lat: info.latitude,
      });
    },
    async confirmBtn() {
      if (!this.form.province) {
        this.$message.warning("请选择区域点位!");
        return;
      }
      try {
        this.loading = true;
        let url = "/ad/area/add";
        let params = {
          ...this.form,
        };

        if (this.areaId) {
          url = "/ad/area/edit";
          params.areaId = this.areaId;
        }

        let res = await this.$http.post(url, params);
        if (res.code === 0) {
          this.$emit("confirm");
          this.$message.success("操作成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#fixed-actionDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-form {
          margin-top: 20px;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
